import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef, Inject, OnDestroy
} from '@angular/core';
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  ResolveStart,
  ResolveEnd
} from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ILayoutConf, LayoutService } from '@cuepid/egret';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.template.html',
})
export class AdminLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly routerEventSub: Subscription;
  public isModuleLoading = false;
  public scrollConfig = {};
  public layoutConf: ILayoutConf = {};
  public adminContainerClasses: any = {};
  private moduleLoaderSub?: Subscription;
  private layoutConfSub?: Subscription;

  constructor(private router: Router, private layout: LayoutService, private cdr: ChangeDetectorRef, @Inject(DOCUMENT) private document: Document) {
    this.routerEventSub = router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe(
      (routeChange: NavigationEnd) => {
        this.layout.adjustLayout({route: routeChange.url});
        this.scrollToTop();
      }
    );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.layout.adjustLayout(event);
  }

  ngOnInit() {
    this.layoutConfSub = this.layout.layoutConf$.subscribe((layoutConf) => {
      this.layoutConf = layoutConf;
      this.adminContainerClasses = this.updateAdminContainerClasses(this.layoutConf);
      this.cdr.markForCheck();
    });

    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.isModuleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
      }
    });
  }

  ngAfterViewInit() {
  }

  scrollToTop() {
    if (this.document) {
      setTimeout(() => {
        let element: HTMLElement | null;
        if (this.layoutConf.topbarFixed) {
          element = this.document.querySelector('#rightside-content-hold');
        } else {
          element = this.document.querySelector('#main-content-wrap');
        }

        if (!element) {
          return;
        }
        element.scrollTop = 0;
      });
    }
  }

  ngOnDestroy() {
    if (this.moduleLoaderSub) {
      this.moduleLoaderSub.unsubscribe();
    }
    if (this.layoutConfSub) {
      this.layoutConfSub.unsubscribe();
    }
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }

  closeSidebar() {
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  sidebarMouseenter() {
    if (this.layoutConf.sidebarStyle === 'compact') {
      this.layout.publishLayoutChange({sidebarStyle: 'full'}, {transitionClass: true});
    }
  }

  sidebarMouseleave() {
    if (this.layoutConf.sidebarStyle === 'full' && this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({sidebarStyle: 'compact'}, {transitionClass: true});
    }
  }

  updateAdminContainerClasses(layoutConf: ILayoutConf) {
    return {
      'navigation-top': layoutConf.navigationPos === 'top',
      'sidebar-full': layoutConf.sidebarStyle === 'full',
      'sidebar-compact': layoutConf.sidebarStyle === 'compact' && layoutConf.navigationPos === 'side',
      'compact-toggle-active': layoutConf.sidebarCompactToggle,
      'sidebar-compact-big': layoutConf.sidebarStyle === 'compact-big' && layoutConf.navigationPos === 'side',
      'sidebar-opened': layoutConf.sidebarStyle !== 'closed' && layoutConf.navigationPos === 'side',
      'sidebar-closed': layoutConf.sidebarStyle === 'closed',
      'fixed-topbar': layoutConf.topbarFixed && layoutConf.navigationPos === 'side'
    }
  }
}
