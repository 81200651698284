<div [perfectScrollbar]="{}" class="height-100vh signup4-wrap" fxLayout="row wrap" fxLayoutAlign="center center">
  <div class="signup4-container mat-elevation-z4 white" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start stretch"
    fxFlex="60" fxFlex.xs="94" fxFlex.sm="80" [@animate]="{value:'*',params:{y:'40px',opacity:'0',delay:'100ms', duration: '400ms'}}">

    <!-- Left Side content -->
    <div fxLayout="column" fxLayoutAlign="center center" class="signup4-header" fxFlex="40">

      <div class="" fxLayout="row wrap" fxLayoutAlign="center center">
        <img width="200px" src="assets/images/illustrations/lighthouse.svg" alt="">
      </div>

    </div>

    <!-- Right side content -->
    <div fxFlex="60" fxLayout="row wrap" fxLayoutAlign="center center">
      <button mat-raised-button color="primary" (click)="signIn()">ログイン</button>
    </div>
  </div>
</div>
