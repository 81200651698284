import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { RoutePartsService, LayoutService } from '@cuepid/egret';
import { filter } from 'rxjs/operators';
import { CompanyService } from './core/services/company.service';
import { CampaignService } from './core/services/campaign.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Cuepid';
  pageTitle = '';

  constructor(public title: Title, private router: Router, private activeRoute: ActivatedRoute, private companyService: CompanyService,
              private routePartsService: RoutePartsService, private layoutService: LayoutService, private campaignService: CampaignService) {
  }

  ngOnInit() {
    this.changePageTitle();
    this.companyService.updateApplicationListCount();
    this.campaignService.updateUnapprovedCampaignCount();
    this.layoutService.applyMatTheme();
  }

  ngAfterViewInit() {
  }

  changePageTitle() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (routeParts.length === 0) {
        return this.title.setTitle(this.appTitle);
      }

      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title)
        .reduce((partA, partI) => `${partA} > ${partI}`);
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
}
