<mat-toolbar class="topbar">
  <!-- Sidenav toggle button -->
  <button *ngIf="layoutConf?.sidebarStyle !== 'compact'" id="sidenavToggle" (click)="toggleSidenav()" matTooltip="Toggle Hide/Open" mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>

  <span fxFlex></span>

  <!-- Top left user menu -->
  <button class="topbar-button-right" [matMenuTriggerFor]="settingMenu" mat-icon-button>
    <mat-icon>settings</mat-icon>
  </button>

  <mat-menu #settingMenu="matMenu">
    <button mat-menu-item [routerLink]="['/profile/overview']">
      <mat-icon>account_box</mat-icon>
      <span>Profile</span>
    </button>
    <button mat-menu-item [routerLink]="['/profile/settings']">
      <mat-icon>settings</mat-icon>
      <span>Account Settings</span>
    </button>
    <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon>
      <span>Disable alerts</span>
    </button>
    <button mat-menu-item [routerLink]="['/sessions/signin']">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sign out</span>
    </button>
  </mat-menu>
</mat-toolbar>
