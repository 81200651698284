import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@cuepid/core';
import { environment } from '@cuepid/admin/env';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(@Inject(WINDOW) private window: Window, private http: HttpClient) {
  }

  signIn() {
    this.window.location.href = `${environment.apiURL}/auth/signIn`;
  }

  renew() {
    return this.http.get(`${environment.apiURL}/auth/renew`);
  }
}
