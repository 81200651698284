import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ILayoutConf, LayoutService, NavigationService } from '@cuepid/egret';
import { CompanyService } from '@cuepid/admin/core/services/company.service';

@Component({
  selector: 'app-sidebar-side',
  templateUrl: './sidebar-side.component.html'
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[] = [];
  public hasIconTypeMenuItem?: boolean;
  public iconTypeMenuTitle?: string;
  public layoutConf?: ILayoutConf;
  private menuItemsSub?: Subscription;

  constructor(private navService: NavigationService, private layout: LayoutService, private companyService: CompanyService) {
  }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;
    });
    this.layoutConf = this.layout.layoutConf;
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }

  handleItemClicked(item: any) {
    if (item.events && item.events.includes("update-application-list-count")) {
      this.companyService.updateApplicationListCount();
    }
  }

}
