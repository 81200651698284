import { Routes } from '@angular/router';
import { IndexComponent } from './pages/index/index.component';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    component: IndexComponent,
  },
  {
    path: 'dashboard',
    component: AdminLayoutComponent,
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'campaign',
    component: AdminLayoutComponent,
    loadChildren: () => import('./pages/campaign/campaign.module').then(m => m.CampaignModule),
  },
  {
    path: 'company',
    component: AdminLayoutComponent,
    loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule),
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

