<div class="sidebar-panel">
  <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
    <div class="sidebar-hold">

      <!-- App Logo -->
      <div class="branding">
        <img src="assets/images/logo.png" alt="" class="app-logo">
        <span class="app-logo-text">Cuepid</span>
        <span style="margin: auto" *ngIf="layoutConf?.sidebarStyle !== 'compact'"></span>
      </div>

      <!-- Navigation -->
      <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem!"
                   [iconMenuTitle]="iconTypeMenuTitle!" (dropdownClicked)="handleItemClicked($event)"></app-sidenav>
    </div>
  </div>
</div>
