import { Component, OnInit, Input } from '@angular/core';
import { ILayoutConf, LayoutService } from '@cuepid/egret';


@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificationPanel: any;

  layoutConf?: ILayoutConf;

  constructor(private layout: LayoutService) {
  }

  ngOnInit() {
    this.layoutConf = this.layout.layoutConf;
  }

  toggleNotification() {
    this.notificationPanel.toggle();
  }

  toggleSidenav() {
    if (this.layoutConf?.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }

    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

}
